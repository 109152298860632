import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Skeleton, Tooltip } from "@mui/material";


import styles from './sidebarLayout.module.scss';
import dropDown from '../../../assets/svg/sidebar/dropDown.svg';
import { useDispatch, useSelector } from "react-redux";
import { toggleFilterDropdown, updateFilterOptionsCache, toggleFilterOption } from "../../../store/sidebar/filterSlice";
import { useGetAvailableFilterOptionsSalesDataAvailableFilterOptionsPost } from "../../../api/sales-metrics-service/salesMetricsComponents";
import { filterMapping } from "../../../constants";
import { marketplaceMapping } from "../../../constants/utils";
import { debounce } from "lodash";

interface FilterDropdownProps {
  label: string;
  clickedLabel: string | null; 
  setClickedLabel: (label: string | null) => void;
  onClick: (label: string) => void;
}

const FilterDropdownComponent: React.FC<FilterDropdownProps> = ({ label, clickedLabel,setClickedLabel}) => {
  const dispatch = useDispatch();
  
  

  const { selectedFilters, filterOptionsCache, filterDropdownStates } = useSelector(
    (state: any) => state.filter
  );
  // console.log('selectedFiltersIn sidebar comp', selectedFilters);
  const { mutate: salesFilterValues, isLoading: loadingFilterValues } = useGetAvailableFilterOptionsSalesDataAvailableFilterOptionsPost();
  const filterHierarchy = ['Super Category', 'Category', 'Sub-Category', 'Segment', 'Sub-Segment', 'Brand', 'Item'];
  
  // const handleToggleFilterDropdown = (filterName: string) => {
  //   // console.log('filterName', filterName);
  //   Object.keys(filterDropdownStates).forEach((key) => {
  //     if (key !== filterName && filterDropdownStates[key]) {
  //       dispatch(toggleFilterDropdown(key));
  //     }
  //   });

  //   dispatch(toggleFilterDropdown(filterName));

  //   if (!filterDropdownStates[filterName]) {
  //     handleSalesFilterClick(filterName);
  //   }
  // };
  const debouncedToggleFilterDropdown = useCallback(
    debounce((filterName: string) => {
      Object.keys(filterDropdownStates).forEach((key) => {
        if (key !== filterName && filterDropdownStates[key]) {
          dispatch(toggleFilterDropdown(key));
        }
      });

      dispatch(toggleFilterDropdown(filterName));
      if (!filterDropdownStates[filterName]) {
        handleSalesFilterClick(filterName);
      }
    }, 1000),
    [filterDropdownStates]
  );

  useEffect(() => {
    // console.log('clickedLabel', clickedLabel);
    if (clickedLabel && label === clickedLabel) {
      setClickedLabel(null);
      debouncedToggleFilterDropdown(label);
      
    }
  }, [label, clickedLabel, debouncedToggleFilterDropdown, setClickedLabel]);
  
  const handleOptionToggle = (label: string, option: string) => {
    const columnName = filterMapping[label];
    dispatch(toggleFilterOption({ label: columnName, option }));
  };
  const selectedHeaderFilter = useSelector(
    (state: any) => state?.headerContext?.dashboardDaysFilter 
  );

  const handleSalesFilterClick = (filter: string) => {
    const columnName = filterMapping[filter];

    const cacheableFilters = ["Marketplace", "Location", "Fulfillment Type", "Promotions Type"];
    const isCacheable = cacheableFilters.includes(filter);
    if (isCacheable && filterOptionsCache[filter]) {
      // console.log(`Returning cached options for ${filter}:`, filterOptionsCache[filter]);
      return filterOptionsCache[filter]; // Return cached options
    }
    // if (filterOptionsCache[filter]) return;  // refetch all the time
    if (filter === "Promotions Type") {
      dispatch(updateFilterOptionsCache({ filter, options: ["Promo", "Non-Promo"] }));
      return;
    }

    salesFilterValues(
      {
        body: {
          time_period: isCacheable ? "alltime" : selectedHeaderFilter,
          // currency_type: 'inr',
          ...selectedFilters,
        },
        queryParams: { column_name: columnName },
      },
      {
        onSuccess: (data: any) => {
          dispatch(updateFilterOptionsCache({ filter, options: data?.values || [] }));
        },
        onError: (error) => {
          console.error('Error fetching options:', error);
        },
      }
    );
  };

  const options = useMemo(() => filterOptionsCache[label] || [], [filterOptionsCache, label]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const filteredOptions = useMemo(() => {
    if (label === "Item") {
      return options.filter(
        (option: { value: string; display_name: string }) =>
          option.display_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
          option.value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    
    }
    if (label=== 'Marketplace'){
      return options.filter(
        (option: any) =>
          marketplaceMapping[option].toLowerCase().includes(searchTerm.toLowerCase()) || 
        marketplaceMapping[option].toLowerCase().includes(searchTerm.toLowerCase())
      );
     
    }
      
    
    
    
    return options.filter((option: string) =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm, label]);

  const truncateText = (text: string) => {
    const words = text.split(' ');
    return words.length > 3 ? `${words.slice(0, 3).join(' ')}...` : text;
  };

  return (
    <div className={styles.filterDropdownContainer} onClick={(e) => e.stopPropagation()}>
      <img
        src={dropDown}
        alt="options"
        onClick={() => debouncedToggleFilterDropdown(label)}
        className={styles.smallIcon}
      />
      {filterDropdownStates[label] && (
        <div className={`${styles.filterDropdown} ${filterDropdownStates[label] ? styles.visible : ''}`}>
          {loadingFilterValues ? (
            <Skeleton variant="rectangular" height={20} sx={{ borderRadius: 2, padding: "10px 0" }} />
          ) : (
            <>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
              {filteredOptions.length === 0 ? (
                <div>No options found</div>
              ) : (
                filteredOptions.map((option: any) => (
                  <Tooltip
                      title={label === 'Item' ? `${option.display_name || 'Unknown'} [${option.value}]` : ''}
                      arrow
                       placement="right"
                       
                      
                    >
                  <label className={styles.filterOption} key={label === "Item" ? option.value : option}
                  //  onClick={() => handleOptionToggle(label, option.value || option)}
                  >
                    <input
                      type="checkbox"
                      checked={
                        (filterHierarchy.includes(label) && (
                          selectedFilters[filterMapping[label]]?.includes(option.value || option) ||
                          (
                            !selectedFilters[filterMapping[label]]?.includes(option.value || option) &&
                            filterHierarchy
                              .slice(filterHierarchy.indexOf(label) + 1)
                              .some(lowerFilter =>
                                (selectedFilters[filterMapping[lowerFilter]] || []).length > 0
                              )
                          )
                        )) || 
                        (!filterHierarchy.includes(label) && 
                          selectedFilters[filterMapping[label]]?.includes(option.value || option)
                        )
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        handleOptionToggle(label, option.value || option);
                      }}
                    />
                    {/* {label === "Item" 
                        ? `${option.display_name || 'Unknown'} [${option.value}]` 
                        : option 
                      } */}
                      {
                      label === 'Item'
                        ? truncateText(`${option.display_name || 'Unknown'} [${option.value}]`)
                        : label === 'Marketplace'
                        ? truncateText(marketplaceMapping[option] || `${option} [Unknown Marketplace]`)
                        : truncateText(option)
                    }
                    
                  </label>
                  </Tooltip>
                ))
              )}
            </>
            )}
        </div>
      )}
    </div>
  );
};

export default memo(FilterDropdownComponent);
