import React, { useState, useEffect } from 'react';
import { ReactComponent as XylicIcon } from '../../assets/svg/xylicIcon.svg';

export const ChatLoadingMessage = ({
  tempMessage,
}: {
  tempMessage: string;
}) => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    setLoadingProgress(0);

    const interval = setInterval(() => {
      setLoadingProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 50 / 60;
      });
    }, 200);

    return () => clearInterval(interval);
  }, [tempMessage]);

  return (
    <div className="flex items-center gap-2 justify-start mt-2">
      <XylicIcon
        width={30}
        height={30}
        style={{
          minWidth: '30px',
          minHeight: '30px',
        }}
      />
      <div
        className="pl-8 p-3 rounded-lg w-[900px] overflow-hidden"
        style={{
          border: '1px solid #E5E1EC',
        }}
      >
        <div className="break-words flex flex-col">
          <span>{tempMessage}...</span>
          <div
            style={{
              height: '4px',
              width: '100%',
              backgroundColor: '#e5e1ec',
              position: 'relative',
              marginTop: '8px',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                height: '100%',
                width: `${loadingProgress}%`,
                backgroundColor: '#5F46FE',
                transition: 'width 1s linear',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
