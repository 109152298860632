export const timeline = [
  '7 days',
  '15 days',
  '1 months',
  '3 months',
  '6 months',
  '1 years',
  '3 years',
  '5 years',
];

export const usStateData = [
  { id: 1, code: 'US-VA', state: 'Virginia' },
  { id: 2, code: 'US-PA', state: 'Pennsylvania' },
  { id: 3, code: 'US-TN', state: 'Tennessee' },
  { id: 4, code: 'US-WV', state: 'West Virginia' },
  { id: 5, code: 'US-NV', state: 'Nevada' },
  { id: 6, code: 'US-TX', state: 'Texas' },
  { id: 7, code: 'US-NH', state: 'New Hampshire' },
  { id: 8, code: 'US-NY', state: 'New York' },
  { id: 9, code: 'US-HI', state: 'Hawaii' },
  { id: 10, code: 'US-VT', state: 'Vermont' },
  { id: 11, code: 'US-NM', state: 'New Mexico' },
  { id: 12, code: 'US-NC', state: 'North Carolina' },
  { id: 13, code: 'US-ND', state: 'North Dakota' },
  { id: 14, code: 'US-NE', state: 'Nebraska' },
  { id: 15, code: 'US-LA', state: 'Louisiana' },
  { id: 16, code: 'US-SD', state: 'South Dakota' },
  { id: 17, code: 'US-DC', state: 'District of Columbia' },
  { id: 18, code: 'US-DE', state: 'Delaware' },
  { id: 19, code: 'US-FL', state: 'Florida' },
  { id: 20, code: 'US-CT', state: 'Connecticut' },
  { id: 21, code: 'US-WA', state: 'Washington' },
  { id: 22, code: 'US-KS', state: 'Kansas' },
  { id: 23, code: 'US-WI', state: 'Wisconsin' },
  { id: 24, code: 'US-OR', state: 'Oregon' },
  { id: 25, code: 'US-KY', state: 'Kentucky' },
  { id: 26, code: 'US-ME', state: 'Maine' },
  { id: 27, code: 'US-OH', state: 'Ohio' },
  { id: 28, code: 'US-OK', state: 'Oklahoma' },
  { id: 29, code: 'US-ID', state: 'Idaho' },
  { id: 30, code: 'US-WY', state: 'Wyoming' },
  { id: 31, code: 'US-UT', state: 'Utah' },
  { id: 32, code: 'US-IN', state: 'Indiana' },
  { id: 33, code: 'US-IL', state: 'Illinois' },
  { id: 34, code: 'US-AK', state: 'Alaska' },
  { id: 35, code: 'US-NJ', state: 'New Jersey' },
  { id: 36, code: 'US-CO', state: 'Colorado' },
  { id: 37, code: 'US-MD', state: 'Maryland' },
  { id: 38, code: 'US-MA', state: 'Massachusetts' },
  { id: 39, code: 'US-AL', state: 'Alabama' },
  { id: 40, code: 'US-MO', state: 'Missouri' },
  { id: 41, code: 'US-MN', state: 'Minnesota' },
  { id: 42, code: 'US-CA', state: 'California' },
  { id: 43, code: 'US-IA', state: 'Iowa' },
  { id: 44, code: 'US-MI', state: 'Michigan' },
  { id: 45, code: 'US-GA', state: 'Georgia' },
  { id: 46, code: 'US-AZ', state: 'Arizona' },
  { id: 47, code: 'US-MT', state: 'Montana' },
  { id: 48, code: 'US-MS', state: 'Mississippi' },
  { id: 49, code: 'US-SC', state: 'South Carolina' },
  { id: 50, code: 'US-RI', state: 'Rhode Island' },
  { id: 51, code: 'US-AR', state: 'Arkansas' },
];
export const marketplaceMapping: Record<string, string> = {
  // North America
  A2EUQ1WTGCTBG2: 'Canada',
  ATVPDKIKX0DER: 'United States of America',
  A1AM78C64UM0Y8: 'Mexico',
  A2Q3Y263D00KWC: 'Brazil',

  // Europe
  A1RKKUPIHCS9HS: 'Spain',
  A1F83G8C2ARO7P: 'United Kingdom',
  A13V1IB3VIYZZH: 'France',
  AMEN7PMS3EDWL: 'Belgium',
  A1805IZSGTT6HS: 'Netherlands',
  A1PA6795UKMFR9: 'Germany',
  APJ6JRA9NG5V4: 'Italy',
  A2NODRKZP88ZB9: 'Sweden',
  A1C3SOZRARQ6R3: 'Poland',
  A33AVAJ2PDY3EV: 'Turkey',
  ARBP9OOSHTCHU: 'Egypt',
  A17E79C6D8DWNP: 'Saudi Arabia',
  A2VIGQ35RCS4UG: 'United Arab Emirates',
  A28R8C7NBKEWEA: 'Ireland',
  AE08WJ6YKNBMC: 'South Africa',

  // Asia-Pacific
  A21TJRUUN4KGV: 'India',
  A39IBJ37TRP1C6: 'Australia',
  A19VAU5U5O7RUS: 'Singapore',
  A1VC38T7YXB528: 'Japan',

  A2N4ZMW9O8HVHL: 'Bahrain',
};
