import React, { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import styles from './styles.module.scss';
import { ReactComponent as DownIcon } from "../../../../../assets/svg/downicon.svg";
import { ReactComponent as UpIcon } from "../../../../../assets/svg/uparr.svg";
import { Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetSalesDataSalesDataFilterSalesDataPost } from '../../../../../api/sales-metrics-service/salesMetricsComponents';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);
const getTrendIcon = (value: number) => {
  if (value < 0) {
    return <DownIcon />;
  } else if (value === 0) {
    return null;
  } else {
    return <UpIcon />;
  }
};

const getTextColor = (value: number) => {
  if (value < 0) {
    return "#F60000";
  } else {
    return "#03A01C";
  }
};
export const SalesChart = () => {
  type SalesDataResponse = {
    start_date: string;
    end_date: string;
    data: number[];
  };
  const selectedFilter = useSelector(
    (state: any) => state?.headerContext?.dashboardDaysFilter
  );
  const { selectedFilters: selectedSidebarFilters} = useSelector(
    (state: any) => state?.filter
  );
  const [totalSales, setTotalSales] = useState(0)
  const [prevTotalSales, setPrevTotalSales] = useState(0);
  // console.log('selectedSidebarFilters', selectedSidebarFilters)
  const [debouncedFilters, setDebouncedFilters] = useState(selectedSidebarFilters);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilters(selectedSidebarFilters);
    }, 1500); // debounce delay in ms

    return () => {
      clearTimeout(handler);
    };
  }, [selectedSidebarFilters]);

  const {
    mutate: fetchSalesData,
    data,
    isLoading
  } = useGetSalesDataSalesDataFilterSalesDataPost();
  // console.log('filter', selectedFilter);
  useEffect(() => {
    setTotalSales(0)
    setPrevTotalSales(0)
    const filterKeys = [
      'super_category',
      'category',
      'sub_category',
      'segment',
      'sub_segment',
    ];

    let productLevelName = null;
    let productLevelValue = null;
    for (let i = filterKeys.length - 1; i >= 0; i--) {
      const key = filterKeys[i];
      if (debouncedFilters[key]?.length > 0) {
        productLevelName = key;
        productLevelValue = debouncedFilters[key];
        break;
      }
    }
    const promotionType =
    debouncedFilters?.promotion_type?.length === 2
      ? null
      : debouncedFilters?.promotion_type?.[0] === 'Promo'
      ? true
      : debouncedFilters?.promotion_type?.[0] === 'Non-Promo'
      ? false
      : null;
    fetchSalesData({
      body: {
        time_period: selectedFilter,
        // currency_type: 'inr',
        marketplace: debouncedFilters?.marketplace,
        location: debouncedFilters?.location,
        fulfillment_type: debouncedFilters?.fulfillment_type,
        brand: debouncedFilters?.brand,
        asin: debouncedFilters?.asin,
        ...(promotionType !== null && { promotion_type: promotionType }),
        ...(productLevelName && {
          product_level_name: productLevelName,
          product_level: productLevelValue,
        }),
      },
    });
  }, [fetchSalesData, selectedFilter, debouncedFilters]);
const chartData = useMemo(() => {
  if (!data || isLoading) return null;
  const salesData = data as { current_period: SalesDataResponse; previous_period: SalesDataResponse };
  const { current_period, previous_period } = salesData;

    const startDate = new Date(current_period.start_date);
    const endDate = new Date(current_period.end_date);
    const isSameDay = startDate.toDateString() === endDate.toDateString();

    let labels: string[];
    let prevLabels: string[];
    let currentChartValues: number[];
    let prevChartValues: number[];
  if (isSameDay) {
    labels = [
      '12-3 AM', '3-6 AM', '6-9 AM', '9-12 AM',
      '12-3 PM', '3-6 PM', '6-9 PM', '9-12 PM',
    ];
    const prevStartDate = new Date(previous_period.start_date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  
    // Construct prevLabels to include the date for the previous period
    prevLabels = labels.map((label) => `${prevStartDate}`);
    // prevLabels = labels.map((label) => `${prevStartDate} ${label}`);
    prevChartValues = previous_period.data;
    currentChartValues = [
      Math.round(Math.abs(salesData.current_period.data[0]) || 0),
      Math.round(Math.abs(salesData.current_period.data[2]) || 0),
      Math.round(Math.abs(salesData.current_period.data[4]) || 0),
      Math.round(Math.abs(salesData.current_period.data[6]) || 0),
      Math.round(Math.abs(salesData.current_period.data[1]) || 0),
      Math.round(Math.abs(salesData.current_period.data[3]) || 0),
      Math.round(Math.abs(salesData.current_period.data[5]) || 0),
      Math.round(Math.abs(salesData.current_period.data[7]) || 0),
    
    ];
    
    prevChartValues = [
      Math.round(Math.abs(salesData.previous_period.data[0]) || 0),
      Math.round(Math.abs(salesData.previous_period.data[2]) || 0),
      Math.round(Math.abs(salesData.previous_period.data[4]) || 0),
      Math.round(Math.abs(salesData.previous_period.data[6]) || 0),
      Math.round(Math.abs(salesData.previous_period.data[1]) || 0),
      Math.round(Math.abs(salesData.previous_period.data[3]) || 0),
      Math.round(Math.abs(salesData.previous_period.data[5]) || 0),
      Math.round(Math.abs(salesData.previous_period.data[7]) || 0),

    ];

  } else {
    const includeYear = selectedFilter === '1y' || selectedFilter === 'alltime';

      labels = Array.from({ length: current_period.data.length }, (_, index) => {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + index);

        return currentDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          ...(includeYear && { year: 'numeric' }),
        });
      });
      prevLabels = Array.from({ length: previous_period.data.length }, (_, index) => {
        const prevDate = new Date(previous_period.start_date);
        prevDate.setDate(prevDate.getDate() + index);
  
        return prevDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          ...(includeYear && { year: 'numeric' }),
        });
      });
      currentChartValues = current_period.data.map((value) => Math.round(Math.abs(value)));
      prevChartValues = previous_period.data.map((value) => Math.round(Math.abs(value)));
    }
  setTotalSales(currentChartValues.reduce((acc, value) => acc + value, 0));
  setPrevTotalSales(prevChartValues.reduce((acc, value) => acc + value, 0));
  const datasets = [
    {
      label: 'Current Period Sales',
      data: currentChartValues,
      borderColor: '#3AA9F4',

      backgroundColor: (context: any) => {
        const ctx = context.chart.ctx;
                  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                  gradient.addColorStop(0, '#8CD2F9');
                  gradient.addColorStop(1, 'rgba(140, 210, 249, 0)');
                  return gradient;
      },
      fill: true,
      borderWidth: 2,
      pointRadius: ['ytd', '1y', 'alltime'].includes(selectedFilter) ? 0 : 4,
      tension: 0.3,
    }]
    if (!selectedFilter.includes('alltime')){
      datasets.push({
        label: 'Previous Period Sales',
        data: prevChartValues,
        borderColor: '#97a6b0',
        borderWidth: 2,
        borderDash: [5, 5] as number[],
        pointRadius: 4,
        tension: 0.3,
      } as any)
    }
  return {
    labels,
    prevLabels,
    datasets: datasets
  };
}, [data, isLoading, selectedFilter]);

const manualLastTickPlugin = {
  id: 'manualLastTickPlugin',
  afterDraw(chart: any) {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x; 
    const yAxis = chart.scales.y; 
    
      if (xAxis && chart.data.labels) {
        const lastIndex = chart.data.labels.length - 1;
        const lastLabel = chart.data.labels[lastIndex];
        const xCoord = xAxis.getPixelForValue(lastIndex);
        const yCoord = yAxis.bottom + 10; 

        ctx.save();
        ctx.fillStyle = '#64748B'; 
        ctx.textAlign = 'right';

        ctx.fillText(lastLabel, xCoord, yCoord);
        ctx.restore();
      
    }
  },
};
if (['ytd', '1y', 'alltime'].includes(selectedFilter)) {
ChartJS.register(manualLastTickPlugin);
}
const options = useMemo<ChartOptions<'line'>>(() => ({
  elements: {
    point: {
      radius: 4,
    },
  },
  interaction: {
    mode: 'nearest',
  },
  hover: {
    mode: 'nearest',
    intersect: true,
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      callbacks: {
        title: function () {
          if (!selectedFilter.includes('1d')){
            return '';
          }
          
        },
        label: function (context: any) {

          const { datasetIndex, dataIndex, chart } = context;
          console.log(chart)
          const datasetLabel = chart.data.datasets[datasetIndex]?.label || '';
          const currentDateLabel = chartData?.labels?.[dataIndex] || '';
          const prevDateLabel = chartData?.prevLabels?.[dataIndex] || '';
          const currentValue = context.raw;
          const prevValue = datasetIndex === 1 ? context.raw : null;

          if (datasetLabel === 'Current Period Sales' ) {
            if (selectedFilter.includes('1d')) {
              const currentDate = new Date(new Date(prevDateLabel).setDate(new Date(chartData?.prevLabels?.[dataIndex] || '').getDate() + 1));
              return `${currentDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}: $${currentValue}`;
            }
            return `${currentDateLabel}: $${currentValue}`;
          } else if (datasetLabel === 'Previous Period Sales') {
            return `${prevDateLabel}: $${prevValue}`;
          }
          return `${datasetLabel}: $${currentValue}`;
        },
      },
    },
    
  },
  scales: {
    y: {
      min: 0,
      grid: {
        drawBorder: false,
        display: true,
      },
      ticks: {
        callback: function (value: any) {
          return `$${Math.round(value)}`;
        },
      },
    },
    x: {
      grid: {
        drawBorder: false,
        display: false,
      },
      ticks: {
        display: true,
        maxRotation: ['ytd', '1y', 'alltime'].includes(selectedFilter) ? 0 : undefined,
        minRotation: ['ytd', '1y', 'alltime'].includes(selectedFilter) ? 0 : undefined,
        callback: function (value, index, ticks) {
          if (chartData?.labels) {
            if (index === 0) {
              return chartData.labels[0];
            }
            // if (index === ticks.length - 1) {
            //   return chartData.labels[chartData.labels.length - 1];
            // }
            if (['ytd', '1y', 'alltime'].includes(selectedFilter)) {
              return '';
            }
            return chartData.labels[index];
          }
          return value;
        },
      },
    },
  },
  maintainAspectRatio: false,
}), [chartData?.labels, selectedFilter]);
  // console.log('selectedFilter', selectedFilter)
  const calculateTrend = (current: number, previous: number) => {
    if (!previous) return 0; // Prevent division by zero
    return parseFloat(((current - previous) / previous * 100).toFixed(1));
  };
  return (
    <div style={{ height: '90%', width: '100%' }}>
      <div className={styles.container}>
        <div className={styles.item}>
          <div className={styles.sales}>
          Total: ${Math.round(totalSales).toLocaleString()}

            <div style={{ display: selectedFilter !== 'alltime' ? "flex" : "none", alignItems: "center", gap: "4px" }}>
            {getTrendIcon(
              calculateTrend(
                totalSales,
                prevTotalSales
              )
            )}
            <Typography
              style={{
                color: getTextColor(
                  calculateTrend(
                    totalSales,
                    prevTotalSales
                  )
                ),
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              { calculateTrend(
                totalSales,
                prevTotalSales
              )}
              % <span style={{ color: "#6B7280" }}>vs prev</span>
            </Typography>
          </div>
          </div>
          </div>
          {/* <div className={styles.item}>
                    <div className={styles.profit}>
                    Previous Period Total: ${prevTotalSales}
                    </div>
                </div> */}

      </div>
      {chartData && !isLoading ? (
        <Chart type="line" data={chartData} options={options} />
      ) : (
        <div className="p-4">
          <Skeleton variant="rectangular" height={20} sx={{ borderRadius: 2 }} />
          <Skeleton variant="rectangular" height={10} sx={{ borderRadius: 2, mt: 1 }} />
          <Skeleton variant="rectangular" height={10} sx={{ borderRadius: 2, mt: 1 }} />
        </div>
      )}
    </div>
  );
};
