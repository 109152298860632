import { useEffect, useState } from "react";
import { SidebarLinkProps } from "../interfaces";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Profile from "../assets/images/profile.jpg";
import settings from "../assets/svg/settingsIcon.svg";
import "./sidebar.scss";
import RightTabs from "../components/molecules/TabPanel";
import { ReactComponent as Dashboard } from '../assets/svg/dashboard.svg';
import { ReactComponent as ManageIcon } from '../assets/svg/manage.svg'
import { ReactComponent as MonetiseIcon } from '../assets/svg/monetise.svg'
import { ReactComponent as OptimizeIcon } from '../assets/svg/optimize.svg';
import { ReactComponent as InsightsIcon } from '../assets/svg/insightsIcon.svg';
import { ReactComponent as CollaborationIcon } from '../assets/svg/collaboration.svg';
import { ReactComponent as MediaIcon } from '../assets/svg/media.svg';
import { ReactComponent as SurveysIcon } from '../assets/svg/surveys.svg';
import { ReactComponent as AttachmentIcon } from '../assets/svg/attachment.svg';
import { ReactComponent as ProductsIcon } from '../assets/svg/products.svg';
import { ReactComponent as EInsightsIcon } from '../assets/svg/EInsights.svg';
import { ReactComponent as ECompaigns } from '../assets/svg/ECompaigns.svg';
import { ReactComponent as EConnections } from '../assets/svg/EConnections.svg';
import { useSelector, useDispatch } from 'react-redux'
import { onMainTabChange, onsubTabChange, switchDashboard } from "../store/header/reducer";
import { SideLayout } from "./sideLayout";
import xylicLogo from "../assets/svg/cross.png";
import { Navbar } from "./navbar";
import { apiRequest } from "../services/onboard";


const SidebarLink: React.FC<{
  link: SidebarLinkProps;
  isSidebarOpen: boolean;
}> = ({ link, isSidebarOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };



  const handleNavigation = (url: string) => {
    if (url) {
      navigate(url);
    } else {
    }
  };

  return (
    <li className={`${isOpen ? "showMenu" : ""}`}>
      <div className="iocn-link" onClick={handleToggle}>
        <p
          onClick={() => handleNavigation(link.path as string)}
          className={link.subMenuHeader ? `submenu-header` : ""}
        >
          {link.icon && <i className={link.icon}></i>}
          <span
            className={`link_name${link.subMenuHeader
              ? ` submenu-header${isSidebarOpen ? "-none" : "-show"}`
              : ""
              }`}
          >
            {link.title}
          </span>
        </p>
        {(link.children?.length as number) > 0 && isSidebarOpen && (
          <i
            className={`bx ${isOpen ? "bxs-chevron-up" : "bxs-chevron-down"
              } arrow`}
          ></i>
        )}
      </div>
      {isOpen && link.children && link.children?.length > 0 && (
        <ul className={`sub-menu`} style={isSidebarOpen ? link.styles : {}}>
          {link.children.map((childLink: SidebarLinkProps, index: number) => (
            <SidebarLink
              key={index}
              link={childLink}
              isSidebarOpen={isSidebarOpen}
            /> // Render nested SidebarLink for each child
          ))}
        </ul>
      )}
    </li>
  );
};
// #5B47F5
const Sidebar = () => {
  const mainTab = useSelector((state: any) => state?.headerContext?.mainHeader)
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate();
  const isDashboard = useSelector((state: any) => state?.headerContext?.isDashboard)
  const { showSide } = useSelector((state: any) => state?.headerContext)


  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate('/login');
    } else if (localStorage.getItem('is_onboarded') === 'false') {
      apiRequest({
          method: 'GET',
          url: `${process.env.REACT_APP_BASE_URL}/auth/onboarding_finished`,
          config: {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          },
          token: localStorage.getItem('access_token') || '',
          dispatch,
          errorMessage: 'Please try again.',
      })
      // Set the onboarding status in localStorage and navigate to the home route
      localStorage.setItem('is_onboarded', "true");
      navigate('/connections')
    }
  }, []);

  const locationHeaderMap: any = {
    "": {
      isEnabled: true, subTab: "", mainTab: "Dashboard"
    },
    "/dashboard": {
      isEnabled: true, subTab: "", mainTab: "Dashboard"
    },
    "/insights": {
      isEnabled: false, subTab: "", mainTab: "Insights"
    },
    "/insights/best-sellers": {
      isEnabled: false, subTab: "Best Sellers", mainTab: "Insights"
    },
    "/insights/customer-behaviour": {
      isEnabled: false, subTab: "Customer Behaviour", mainTab: "Insights"
    },
    "/insights/segmentation": {
      isEnabled: false, subTab: "Segmentation", mainTab: "Insights"
    },
    "/media/manage-campaign": {
      isEnabled: false, subTab: "Manage Campaigns", mainTab: "Media"
    },
    "/media/measurement-and-analytics": {
      isEnabled: false, subTab: "Measurement and Analytics", mainTab: "Media"
    },
    "/supplychain": {
      isEnabled: false, subTab: "", mainTab: "Supply Chain"
    },
    "/manage": {
      isEnabled: true, subTab: "Connect Data", mainTab: "Manage"
    },
    "/data-animator": {
      isEnabled: true, subTab: "Connect Data", mainTab: "Manage"
    },
    "/subscribers": {
      isEnabled: true, subTab: "Subscribers", mainTab: "Manage"
    },
    "/on-boarding": {
      isEnabled: true, subTab: "On Boarding", mainTab: "Manage"
    },
  }

  useEffect(() => {
    const headData: any = locationHeaderMap[location.pathname]
    if (headData) {
      dispatch(switchDashboard({ isEnabled: headData.isEnabled }))
      dispatch(onMainTabChange({ name: headData.mainTab }))
      dispatch(onsubTabChange({ name: headData.subTab }))
    } else {
      dispatch(onMainTabChange({ name: "" }))
      dispatch(onsubTabChange({ name: "" }))
    }
  }, [location.pathname])

  const tabdata = [
    { name: 'Insights', id: 0, value: 'insights', icon: <InsightsIcon fill={mainTab === 'Insights' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Collaboration', id: 0, value: 'collaboration', icon: <CollaborationIcon style={{ marginTop: '2px' }} fill={mainTab === 'Collaboration' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Media', id: 0, value: 'media/manage-campaign', icon: <MediaIcon style={{ marginTop: '2px' }} fill={mainTab === 'Media' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Surveys', id: 0, value: 'surveys', icon: <SurveysIcon style={{ marginTop: '2px' }} fill={mainTab === 'Surveys' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Supply Chain', id: 0, value: 'supplychain', icon: <AttachmentIcon style={{ marginTop: '2px' }} fill={mainTab === 'Supply Chain' ? "rgb(95, 70, 255)" : '#888888'} /> }
  ];


  const dash_tabdata = [
    { name: 'Dashboard', id: 0, value: 'dashboard', icon: <ProductsIcon fill={mainTab === 'Dashboard' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Manage', id: 0, value: 'manage', icon: <ManageIcon style={{ marginTop: '2px' }} fill={mainTab === 'Manage' ? "rgb(95, 70, 255)" : '#888888'} /> },
    //   { name: 'Monetise', id: 0, value: 'monetise', icon: <MonetiseIcon style={{ marginTop: '2px' }} fill={mainTab === 'Monetise' ? "rgb(95, 70, 255)" : '#888888'} /> },
    //{ name: 'Optimize', id: 0, value: 'optimize', icon: <OptimizeIcon style={{ marginTop: '2px' }} fill={mainTab === 'Optimize' ? "rgb(95, 70, 255)" : '#888888'} /> },
  ];

  const emptyDashData = [
    { name: 'Insights', link: '', id: 0, value: '', icon: <EInsightsIcon fill={mainTab === 'Dashboard' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Campaigns', link: '', id: 0, value: '', icon: <ECompaigns style={{ marginTop: '2px' }} fill={mainTab === 'Manage' ? "rgb(95, 70, 255)" : '#888888'} /> },
    { name: 'Connections', link: 'connections', id: 0, value: 'connections', icon: <EConnections style={{ marginTop: '2px' }} fill={mainTab === 'Manage' ? "rgb(95, 70, 255)" : '#888888'} /> },
  ];
  const handleType = (item: any, portal: string) => {
    navigate(`${item?.value}`)
    // dispatch(onMainTabChange(item));
    // if (item.name === 'Media') {
    //   dispatch(onsubTabChange({ name: 'Manage Campaigns', id: 0, value: '/media/manage-campaign' }))
    // } else {
    //   dispatch(onsubTabChange(''))
    // }
  }
  return (
    <>

      <div className="home-content" >
        <Navbar {...{ xylicLogo, settings, showSide, location, mainTab, handleType, emptyDashData, isDashboard, Profile, dash_tabdata, tabdata }} />
        <section className={`home-section ${!showSide ? '' : 'close'}`}
          style={
            location.pathname == "/data-animator" || location.pathname == "/home" || location.pathname == "/connections" || location.pathname == "/manage" || location.pathname == "/on-boarding" || location.pathname == "/monetise" || location.pathname.startsWith("/media") || location.pathname == "/not-found"
              ? { left: 0, width: "100%" }
              : { left: showSide ? 0 : '' }
          }
        >
          <Outlet />
        </section>
      </div>
    </>
  );
}

export default Sidebar;
