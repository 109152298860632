import React, { useState } from 'react';
import {TextField, Select, MenuItem, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
interface Option {
  label: string;
  value: string;
}

interface Field {
  label: string;
  type: 'text' | 'number' | 'dropdown' | 'date-range' | 'radio';
  value?: string | number;
  startDate?: string;
  endDate?: string;
  options?: Option[];
}

interface CreateCampaignFormProps {
  fields: Field[];
  onSubmit: (formData: Record<string, any>) => void;
  onSaveDraft: (draftData: Record<string, any>) => void;
  onDiscard: () => void;
}

const CreateCampaignForm: React.FC<CreateCampaignFormProps> = ({ fields, onSubmit, onSaveDraft, onDiscard }) => {
  const [formData, setFormData] = useState(
    fields.reduce((acc, field) => {
      if (field.type === 'date-range') {
        acc[field.label] = { startDate: field.startDate || '', endDate: field.endDate || '' };
      } else {
        acc[field.label] = field.value || '';
      }
      return acc;
    }, {} as Record<string, any>)
  );

  const handleChange = (label: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [label]: value,
    }));
  };

  const handleDateRangeChange = (label: string, dateType: 'startDate' | 'endDate', value: string) => {
    setFormData((prev) => ({
      ...prev,
      [label]: {
        ...prev[label],
        [dateType]: value,
      },
    }));
  };

  return (
    <>
        <h3
      style={{
        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        color: '#5E5C71',
      }}
    >
      Create Campaign
    </h3>
    <div
        className="create-campaign-form"
        style={{
          padding: '20px',
          maxWidth: '600px',
          margin: '0 auto',
          fontFamily: 'Inter, sans-serif',
          fontSize: '14px',
          color: '#5E5C71',
        }}
      >
          {fields.map((field, index) => (
    <div key={index} style={{ marginBottom: '10px' }}>
      <label
        style={{
          display: 'block',
          fontWeight: 'bold',
          marginBottom: '5px',
        }}
      >
        {field.label}:
      </label>
            {field.type === 'dropdown' && (
              <Select
                value={formData[field.label] || ''}
                onChange={(e) => handleChange(field.label, e.target.value)}
                fullWidth
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  color: '#5E5C71',
                  height: '35px'
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                    },
                  },
                }}
              >
                <MenuItem value="" style={{ fontFamily: 'Inter, sans-serif', fontSize: '14px' }}>
                  Select {field.label.toLowerCase()}
                </MenuItem>
                {field.options?.map((option, i) => (
                  <MenuItem
                    key={i}
                    value={option.value}
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                      color: '#5E5C71',
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
            {field.type === 'text' && (
              <TextField
                value={formData[field.label]}
                onChange={(e) => handleChange(field.label, e.target.value)}
                placeholder={`Enter ${field.label.toLowerCase()}...`}
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '14px',
                    color: '#5E5C71',
                    height: '35px'
                  },
                }}
              />
            )}
            {field.type === 'number' && (
              <TextField
                type="number"
                value={formData[field.label]}
                onChange={(e) => handleChange(field.label, e.target.value)}
                placeholder={`Enter ${field.label.toLowerCase()}...`}
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '14px',
                    color: '#5E5C71',
                    height: '35px'
                  },
                }}
              />
            )}
            {field.type === 'date-range' && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <TextField
                  type="date"
                  value={formData[field.label]?.startDate}
                  onChange={(e) => handleDateRangeChange(field.label, 'startDate', e.target.value)}
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                      color: '#5E5C71',
                      height: '35px'
                    },
                  }}
                />
                <TextField
                  type="date"
                  value={formData[field.label]?.endDate}
                  onChange={(e) => handleDateRangeChange(field.label, 'endDate', e.target.value)}
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                      color: '#5E5C71',
                      height: '35px'
                    },
                  }}
                />
              </div>
            )}
            {field.type === 'radio' && (
            <RadioGroup
              value={formData[field.label]}
              onChange={(e) => handleChange(field.label, e.target.value)}
              row
            >
              {field.options?.map((option, i) => (
                <FormControlLabel
                  key={i}
                  value={option.value}
                  control={
                    <Radio
                      sx={{
                        color: 'inherit', 
                        '&.Mui-checked': {
                          color: '#5F46FE',
                        },
                        height: '20px'
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        color:
                          formData[field.label] === option.value ? '#5F46FE' : 'inherit',
                      }}
                    >
                      {option.label}
                    </span>
                  }
                />
              ))}
            </RadioGroup>
          )}
          </div>
        ))}

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <button
            className="bg-[#5F46FE] active:scale-75 cursor-pointer p-1 pr-4 border border-[#5E5C71] text-white rounded-lg max-w-[120px] mr-4 hover:border-[#4CAF50] hover:bg-[#4CAF50] flex items-center justify-center gap-2"
            onClick={() => onSubmit(formData)}
          >
            <CheckIcon className="text-white-500 w-5 h-5" /> Create
          </button>
          <button
            className="bg-white active:scale-75 cursor-pointer p-2 pr-4 border border-[#5E5C71] text-[#5E5C71] rounded-lg max-w-[180px] mr-1 hover:border-[#4CAF50] hover:bg-[#5CB85C1A] flex items-center justify-center gap-2"
            onClick={() => onSaveDraft(formData)}
          >
             <SaveIcon className="text-#5E5C71-500 w-5 h-5" />  Save as Draft
          </button>
          <button
            className="bg-white active:scale-75 cursor-pointer p-2 text-[#5E5C71] rounded-lg max-w-[120px] mr-1 border-transparent hover:bg-[#f5b5b0] flex items-center justify-center gap-2 outline-none focus:outline-none"
            onClick={onDiscard}
          >
            <CloseIcon className="text-red-500 w-5 h-5" /> Discard
          </button>

        </div>
      </div>
    </>
  );
};

export default CreateCampaignForm;
