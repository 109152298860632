import { Grid, CssBaseline, Typography, Button } from '@mui/material';
import { createTheme } from '@mui/system';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { addToast } from '../../../../store/toastSlice';
import styles from '../styles.module.scss';
import { ReactComponent as OnboardLogo } from '../../../../assets/svg/loginLogo.svg';
import amazon from '../../../../assets/images/amazon.png';
import second from '../../../../assets/images/datasource.png';

const defaultTheme = createTheme();

const VendorOnboardPage = () => {
  const navigate = useNavigate();
  const [selectedPlatform, setSelectedPlatform] = React.useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const conn = queryParams.get('conn');
  const dispatch = useDispatch();

  const handleCardClick = (platform: any) => {
    if (selectedPlatform === 'amazon') {
      setSelectedPlatform(null);
    } else {
      setSelectedPlatform(platform);
    }
  };

  const handleProceed = () => {
    if (selectedPlatform) {
      navigate(`/amazon-onboard-venodr?conn=${conn}`);
    }
  };
  //  did not change handle cancel need to fix with the api endpoint
  const handleCancel = async () => {
    try {
      const token = localStorage.getItem('access_token'); // Get the bearer token from local storage
      const headers = { Authorization: `Bearer ${token}` };

      // Call the onboarding finished endpoint
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/auth/onboarding_finished`,
        { headers }
      );
      localStorage.setItem('is_onboarded', 'true');
      // Navigate to the home route
      if (conn === 'false') {
        navigate('/home');
      } else {
        navigate('/connections');
      }
    } catch (error) {
      console.error('Error during cancel operation:', error);
      dispatch(
        addToast({
          id: Date.now(),
          type: 'error',
          message: 'Please try again.',
        })
      );
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ minHeight: '100vh', overflow: 'auto' }}
        className={`font-poppins ${styles.mainCont}`}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#5B47F5',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: '0 40px',
          }}
          className="max-sm:px-0"
        >
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              overflow: 'auto',
            }}
            className="max-sm:hidden"
          >
            <Grid
              sx={{
                gap: '20px',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: 'white',
                  fontWeight: 800,
                  fontSize: '36px',
                  lineHeight: '44px',
                  letterSpacing: '0.5px',
                }}
              >
                Welcome to Xylic
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: 'white',
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '28px',
                }}
              >
                Streamline your business processes and boost productivity.
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid
            sx={{
              position: "fixed",
              top: 0,
              right: "33.3%",
              filter: "blur(200px)",
              // background: 'rgb(207, 200, 255)',
              height: "40%",
              width: "30%",
            }}
          /> */}
          {/* <Grid
            sx={{
              position: "fixed",
              bottom: 10,
              filter: "blur(200px)",
              // background: 'rgb(207, 200, 255)',
              height: "30%",
              width: "20%",
            }}
          /> */}
          <Grid
            sx={{
              position: 'fixed',
              bottom: -30,
              left: -30,
            }}
            className="max-sm:hidden"
          >
            <OnboardLogo width={'60%'} />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={7}
          sx={{
            overflow: { xs: 'auto', sm: 'auto' },
            background: 'white',
            borderRadius: '8px',
            // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            display: 'flex',
            // justifyContent: "center",
            maxHeight: { xs: 'calc(100vh - 130px)', md: 'calc(100vh + 200px)' },
            flexDirection: 'column',
            padding: { xs: '60px 24px 0px 24px', sm: '20px 80px' },
            overFlowX: 'hidden',
            gap: '24px',
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            sx={{
              marginBottom: '0px',
              fontWeight: 600,
              color: '#2C3E50',
              textAlign: 'start',
            }}
            className={styles.headerTextTitle}
          >
            Choose your Platform
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: '32px',
              fontWeight: 400,
              fontSize: '16px',
              color: '#000',
              textAlign: 'start',
            }}
            className={styles.decTextTitle}
          >
            Select the marketplace you would like to connect.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: '16px',
              fontWeight: 400,
              fontSize: '16px',
              color: '#34495E',
              textAlign: 'start',
            }}
            className={styles.decTextTitle}
          >
            What account would you like to connect?
          </Typography>
          <Grid
            container
            spacing={4}
            justifyContent="start"
            style={{
              display: 'flex',
              gap: '20px',
              marginLeft: 0,
              paddingTop: '10px',
              marginTop: '0px',
            }}
            className="max-sm:px-0 max-sm:w-full max-sm:justify-center"
          >
            <Grid
              item
              sx={{
                textAlign: 'center',
                border: `2px solid ${
                  selectedPlatform === 'amazon' ? '#5B47F5' : '#E0E0E0'
                }`,
                borderRadius: '8px',
                // width: "240px",
                boxShadow:
                  selectedPlatform === 'amazon'
                    ? '0 6px 12px rgba(91, 71, 245, 0.2)'
                    : 'none',
                padding: '16px',
                paddingRight: '24px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // transition:
                //   "transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease",
                // "&:hover": {
                //   transform: "scale(1.05)",
                //   boxShadow: "0 6px 12px rgba(91, 71, 245, 0.2)",
                //   borderColor: "#5B47F5",
                // },
              }}
              className={styles.imageContainer}
              onClick={() => handleCardClick('amazon')}
            >
              <img src={amazon} alt="Amazon" width="140px" height="100px" />
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'start',
                  marginTop: '16px',
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#34495E',
                  width: '100%',
                }}
                className={styles.cardHeaderText}
              >
                Connect to Amazon
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'start',
                  marginTop: '8px',
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#7F8C8D',
                }}
                className={styles.cardInfoText}
              >
                Connect your Amazon Vendor Central account to Xylic
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                textAlign: 'center',
                border: `2px solid ${
                  selectedPlatform === 'custom' ? '#5B47F5' : '#E0E0E0'
                }`,
                borderRadius: '8px',
                boxShadow:
                  selectedPlatform === 'custom'
                    ? '0 6px 12px rgba(91, 71, 245, 0.2)'
                    : 'none',
                padding: '16px',
                width: '240px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                transition:
                  'transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease',
                opacity: 0.5, // Reduces opacity to create a fade-out effect
                pointerEvents: 'none', // Disables user interaction
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 6px 12px rgba(91, 71, 245, 0.2)',
                  borderColor: '#5B47F5',
                },
              }}
              className={styles.imageContainer}
            >
              <img
                src={second}
                alt="BYOD"
                width="130px"
                height="100px"
                className={styles.imgStyles}
              />
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'start',
                    marginTop: '16px',
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#34495E',
                    width: '100%',
                    margin: '0 auto',
                  }}
                  className={styles.cardHeaderText}
                >
                  BYOD
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'start',
                    marginTop: '8px',
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#7F8C8D',
                  }}
                  className={styles.cardInfoText}
                >
                  Connect your custom data sources
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            sx={{
              marginTop: { xs: '10px', md: '200px' },
              gap: '16px',
              marginBottom: '24px',
            }}
            className={styles.bottomSection}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleCancel()}
              sx={{
                height: '40px',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleProceed}
              disabled={!selectedPlatform}
              sx={{
                backgroundColor: selectedPlatform ? '#5B47F5' : '#E0E0E0',
                '&:hover': {
                  backgroundColor: selectedPlatform ? '#4739B6' : '#D0D0D0',
                },
                height: '40px', // Adjust distance from left as needed
              }}
            >
              Proceed
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default VendorOnboardPage;
