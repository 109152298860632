import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  Grid,
  useMediaQuery,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import PulseLoader from 'react-spinners/PulseLoader';
import { handleNavigate } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { lab } from 'd3';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const Connections = () => {
  interface Connection {
    id: string;
    region: string;
    connectedOn: string;
    isConnected: boolean;
  }

  const [connectionsData, setConnectionsData] = useState<Connection[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMarketplaces = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) throw new Error('No token found');

        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/amazon/connected-marketplaces`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const finalData = response?.data?.marketplaces.map((item: any) => {
          const connectedDate = new Date(item?.connected_at);
          const formattedDate = connectedDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });

          return {
            id: item?.marketplace_id,
            region: item?.country,
            connectedOn: formattedDate,
            isConnected: true,
          };
        });
        setConnectionsData(finalData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchMarketplaces();
  }, []);

  // marketplaces grouped by region
  const regions = [
    {
      name: 'North America',
      countries: ['United States of America', 'Canada', 'Mexico', 'Brazil'],
    },
    {
      name: 'Europe',
      countries: [
        'Spain',
        'United Kingdom',
        'France',
        'Belgium',
        'Netherlands',
        'Germany',
        'Italy',
        'Sweden',
        'South Africa',
        'Poland',
        'Egypt',
        'Turkey',
        'Saudi Arabia',
        'United Arab Emirates',
        'India',
      ],
    },
    {
      name: 'Far East',
      countries: ['Australia', 'Singapore', 'Japan'],
    },
  ];

  const regionTiles = regions.map((region) => {
    const regionMarkets = connectionsData.filter((market) =>
      region.countries.includes(market.region)
    );

    const isConnected = regionMarkets.some((market) => market.isConnected);
    const latestConnectedOn = isConnected
      ? regionMarkets
          .filter((market) => market.isConnected)
          .reduce(
            (latest, market) =>
              new Date(market.connectedOn) > new Date(latest)
                ? market.connectedOn
                : latest,
            '1970-01-01'
          )
      : null;

    return {
      region: region.name,
      isConnected,
      connectedOn: latestConnectedOn,
    };
  });

  const [vendorConnectionsData, setVendorConnectionsData] = useState<Connection[]>([]);
  const [loadingVendorConnection, setLoadingVendorConnection] = useState(false);
  useEffect(() => {
    const fetchMarketplaces = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) throw new Error('No token found');

        setLoadingVendorConnection(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/amazon/vendor/connected-marketplaces`, // change this url for vendor connection fetching
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const finalData = response?.data?.marketplaces.map((item: any) => {
          const connectedDate = new Date(item?.connected_at);
          const formattedDate = connectedDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });

          return {
            id: item?.marketplace_id,
            region: item?.country,
            connectedOn: formattedDate,
            isConnected: true,
          };
        });
        setVendorConnectionsData(finalData);
        setLoadingVendorConnection(false);
      } catch (err) {
        setLoadingVendorConnection(false);
      }
    };

    fetchMarketplaces();
  }, []);
  const vendorRegionTiles = regions.map((region) => {
    const regionMarkets = vendorConnectionsData.filter((market) =>
      region.countries.includes(market.region)
    );

    const isConnected = regionMarkets.some((market) => market.isConnected);
    const latestConnectedOn = isConnected
      ? regionMarkets
          .filter((market) => market.isConnected)
          .reduce(
            (latest, market) =>
              new Date(market.connectedOn) > new Date(latest)
                ? market.connectedOn
                : latest,
            '1970-01-01'
          )
      : null;

    return {
      region: region.name,
      isConnected,
      connectedOn: latestConnectedOn,
    };
  });

  const [adsConnectedRegions, setAdsConnectedRegions] = useState<Connection[]>(
    []
  );
  const [loadingAdsConnection, setLoadingAdsConnection] = useState(false);

  useEffect(() => {
    const fetchConnectedRegions = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) throw new Error('No token found');

        setLoadingAdsConnection(true);
        // url: `${process.env.REACT_APP_BASE_URL}/amazon_ads/api/amazon_ads/auth_url?region=${region}`,
        const response = await axios.get(
          // 'http://localhost:8000/amazon_ads/regions',
          `${process.env.REACT_APP_BASE_URL}/amazon_ads/regions`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const finalData = Object.entries(response?.data?.regions || {}).map(
          ([regionKey, item]: [string, any]) => {
            const connectedDate = new Date(item?.connected_at);
            const formattedDate = connectedDate.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            });

            return {
              id: regionKey,
              region: regionKey,
              connectedOn: formattedDate,
              isConnected: true,
            };
          }
        );

        setAdsConnectedRegions(finalData);
        setLoadingAdsConnection(false);
      } catch (err) {
        setLoadingAdsConnection(false);
        console.error(err);
      }
    };

    fetchConnectedRegions();
  }, []);

  const adsRegions = [
    {
      name: 'NA',
      label: 'North America',
    },
    {
      name: 'EU',
      label: 'Europe',
    },
    {
      name: 'FE',
      label: 'Far East',
    },
  ];

  const adsRegionTiles = adsRegions.map((region) => {
    const regionMarkets = adsConnectedRegions.filter(
      (market) => market.region.replace(/'/g, '') === region.name // Remove single quotes
    );

    const isConnected = regionMarkets.length > 0;
    const latestConnectedOn = isConnected
      ? regionMarkets.reduce(
          (latest, market) =>
            new Date(market.connectedOn) > new Date(latest)
              ? market.connectedOn
              : latest,
          '1970-01-01'
        )
      : null;

    return {
      region: region.name,
      label: region.label,
      isConnected,
      connectedOn: latestConnectedOn,
    };
  });
  const navigate = useNavigate();
  const { showSide } = useSelector((state: RootState) => state.headerContext);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  if (loading || loadingAdsConnection || loadingVendorConnection) 
    return (
      <div
        style={{
          background: '#FFFEFB',
          boxShadow: '2px 1px 6px 1px #00000040',
          height: isSmallScreen || showSide ? '100%' : 'calc(100% + 44px)',
          marginTop: isSmallScreen || showSide ? 0 : '-44px',
          filter: isSmallScreen && !showSide ? 'blur(4px)' : '',
          minWidth: isSmallScreen && !showSide ? '680px' : '0px',
          border: '1px solid #E5E1EC',
        }}
        className="flex items-center  h-full  rounded-lg"
      >
        <div
          style={{}}
          className="flex flex-col items-center h-full w-full  p-5  gap-5 justify-between"
        >
          {' '}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <PulseLoader color="rgb(91, 71, 245)" size={10} />
          </Box>
        </div>
      </div>
    );

  return (
    <div
      style={{
        boxShadow: '2px 1px 6px 1px #00000040',
        background: '#FFFEFB',
        border: '1px solid #E5E1EC',
        display: 'block',
        height: isSmallScreen || showSide ? '100%' : 'calc(100% + 44px)',
        marginTop: isSmallScreen || showSide ? 0 : '-44px',
        filter: isSmallScreen && !showSide ? 'blur(4px)' : '',
        minWidth: isSmallScreen && !showSide ? '680px' : '0px',
      }}
      className="h-full  w-full  p-5  rounded-lg overflow-scroll "
    >
      <div className="flex flex-col">
        <span className="font-bold mb-3 text-xl">Amazon Connections</span>

        <span className="mt-4 mb-2 text-lg">
          Amazon Seller Central Connection
        </span>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 bg-[#FFFEFB]">
          {regionTiles.map((regionTile, index) => (
            <div
              key={index}
              className="flex flex-col p-3 bg-[#FFFEFB] rounded-md shadow-[1px_1px_1px_1px_rgba(0,0,0,0.25)] border border-[#E5E1EC]"
            >
              <h6 className="text-[18px] font-medium">{regionTile.region}</h6>
              {regionTile.isConnected ? (
                <>
                  <div className="flex items-center mt-1">
                    <CheckCircleIcon className="text-green-500 w-5 h-5" />
                    <p className="text-gray-500 ml-1">Connected</p>
                  </div>
                  <div className="flex mt-1">
                    <p className="text-gray-500 min-w-[180px]">
                      Last Updated: {regionTile.connectedOn}
                    </p>
                  </div>
                </>
              ) : (
                <button
                  onClick={() => navigate(`/onboard?conn=true`)}
                  className="bg-white active:scale-75 cursor-pointer py-1 border border-[#5CB85C] text-[#5CB85C] rounded-lg max-w-[120px] mt-2 hover:border-[#4CAF50] hover:bg-[#5CB85C1A]"
                >
                  Authorize
                </button>
              )}
            </div>
          ))}
        </div>

        <span className="mt-6 mb-2 text-lg">Amazon Ads Connection</span>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 bg-[#FFFEFB]">
          {adsRegionTiles.map((regionTile, index) => (
            <div
              key={index}
              className="flex flex-col p-3 bg-[#FFFEFB] rounded-md shadow-[1px_1px_1px_1px_rgba(0,0,0,0.25)] border border-[#E5E1EC]"
            >
              <h6 className="text-[18px] font-medium">{regionTile.label}</h6>
              {regionTile.isConnected ? (
                <>
                  <div className="flex items-center mt-1">
                    <CheckCircleIcon className="text-green-500 w-5 h-5" />
                    <p className="text-gray-500 ml-1">Connected</p>
                  </div>
                  <div className="flex mt-1">
                    <p className="text-gray-500 min-w-[180px]">
                      Last Updated: {regionTile.connectedOn}
                    </p>
                  </div>
                </>
              ) : (
                <button
                  onClick={() =>
                    navigate(
                      `/amazon-ads-onboard?conn=true&region=${regionTile.region}`
                    )
                  }
                  className="bg-white active:scale-75 cursor-pointer py-1 border border-[#5CB85C] text-[#5CB85C] rounded-lg max-w-[120px] mt-2 hover:border-[#4CAF50] hover:bg-[#5CB85C1A]"
                >
                  Authorize
                </button>
              )}
            </div>
          ))}
        </div>
        <span className="mt-6 mb-2 text-lg">Amazon Vendor Central Connection</span>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 bg-[#FFFEFB]">
          {vendorRegionTiles.map((regionTile, index) => (
            <div
              key={index}
              className="flex flex-col p-3 bg-[#FFFEFB] rounded-md shadow-[1px_1px_1px_1px_rgba(0,0,0,0.25)] border border-[#E5E1EC]"
            >
              <h6 className="text-[18px] font-medium">{regionTile.region}</h6>
              {regionTile.isConnected ? (
                <>
                  <div className="flex items-center mt-1">
                    <CheckCircleIcon className="text-green-500 w-5 h-5" />
                    <p className="text-gray-500 ml-1">Connected</p>
                  </div>
                  <div className="flex mt-1">
                    <p className="text-gray-500 min-w-[180px]">
                      Last Updated: {regionTile.connectedOn}
                    </p>
                  </div>
                </>
              ) : (
                <button
                  onClick={() => navigate(`/vendor-onboard?conn=true`)}
                  className="bg-white active:scale-75 cursor-pointer py-1 border border-[#5CB85C] text-[#5CB85C] rounded-lg max-w-[120px] mt-2 hover:border-[#4CAF50] hover:bg-[#5CB85C1A]"
                >
                  Authorize
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Connections;
