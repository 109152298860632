// ProtectedRoute.tsx
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode'; // Named import
import { addToast } from './store/toastSlice';

interface ProtectedRouteProps {
  children?: React.ReactNode;
}

interface MyJwtPayload {
  exp: number;
}

const isTokenValid = (token: string | null): boolean => {
  // console.log('checking token validity')
  if (!token) return false;
  try {
    const { exp } = jwtDecode<MyJwtPayload>(token);
    return exp * 1000 > Date.now();
  } catch (error) {
    return false;
  }
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('access_token');
  const isAuthenticated = token && isTokenValid(token);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(
        addToast({
          message: 'Please login to access this page',
          severity: 'error',
        })
      );
      localStorage.removeItem('access_token');
    }
  }, [isAuthenticated, dispatch]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <>{children ? children : <Outlet />}</>;
};

export default ProtectedRoute;
