import { SidebarLinkProps } from '../interfaces';
export const sidebarLinks: SidebarLinkProps[] = [
  {
    title: 'Dashboard',
    icon: 'bx bx-grid-alt',
    children: [
      {
        subMenuHeader: true,
        title: 'Dashbboard',
      },
      {
        title: 'Sam1',
        path: '/sam1',
      },
    ],
  },
  {
    title: 'Category',
    icon: 'bx bx-collection',
    children: [
      {
        subMenuHeader: true,
        title: 'Category',
      },
      { title: 'HTML & CSS', path: 'html' },
      { title: 'JavaScript', path: 'js' },
      { title: 'PHP & MySQL', path: 'php' },
    ],
  },
  {
    title: 'Posts',
    icon: 'bx bx-book-alt',
    children: [
      {
        subMenuHeader: true,
        title: 'Posts',
      },
      { title: 'Web Design', path: '/web' },
      { title: 'Login Form', path: '/login' },
      { title: 'Card Design', path: '/card' },
    ],
  },
  {
    title: 'Analytics',
    icon: 'bx bx-pie-chart-alt-2',
    path: '/analytics',
    children: [],
  },
  {
    title: 'Chart',
    icon: 'bx bx-line-chart',
    children: [
      {
        subMenuHeader: true,
        title: 'Chart',
      },
      { title: 'Sample', path: '/sample' },
      { title: 'cse', path: '/cse' },
    ],
  },
  {
    title: 'Plugins',
    icon: 'bx bx-plug',
    children: [
      {
        subMenuHeader: true,
        title: 'Plugins',
      },
      { title: 'UI Face', path: '/uiface' },
      { title: 'Pigments', path: '/pigments' },
      { title: 'Box Icons', path: '/boxicons' },
    ],
  },
  {
    title: 'Explore',
    icon: 'bx bx-compass',
    path: '/explore',
    children: [],
  },
  {
    title: 'History',
    icon: 'bx bx-history',
    path: '/history',
    children: [],
  },
  {
    title: 'Setting',
    icon: 'bx bx-cog',
    path: '/settings',
    children: [],
  },
  {
    title: 'Backend',
    icon: 'bx bx-cog',
    children: [
      {
        subMenuHeader: true,
        title: 'Backend',
      },
      {
        title: 'Node',
        path: '/node',
      },
      {
        title: 'Express',
        styles: {
          marginLeft: '-50px',
        },
        children: [
          {
            subMenuHeader: true,
            title: 'Express',
          },
          {
            title: 'Link2',
            path: '/link2',
          },
          {
            title: 'Smap2',
            path: '/smap2',
          },
        ],
      },
    ],
  },
];

export const filterMapping: Record<string, string> = {
  Marketplace: 'marketplace',
  Location: 'location',
  'Fulfillment Type': 'fulfillment_type',
  'Promotions Type': 'promotion_type',
  'Super Category': 'super_category',
  Category: 'category',
  'Sub-Category': 'sub_category',
  Segment: 'segment',
  'Sub-Segment': 'sub_segment',
  Brand: 'brand',
  Item: 'asin',
};
